<template>
<!-- eslint-disable -->
	<div style="height: 100vh">
		<h2 class="text-center" style="font-size: 32px!important; margin: 65px 0 40px 10px;">FIRE DAILY<span class="ml-1">
			
			<v-menu
				v-model="menu"
				origin="center center"
				transition="scale-transition"
				offset-y
				min-width="auto"
 				left 
				offset-x
				nudge-bottom="20"
				nudge-right="95"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-icon
						v-bind="attrs"
						v-on="on"
						@click="itemUseFlg = false"
					>mdi-help-circle-outline</v-icon>
				</template>
				<v-card>
					<v-card-text v-if="!itemUseFlg" style="width: 330px;">
						最高の一日を確実に共有するために、<br/>FIRE DAILYを使いましょう！<br/>使用takenoによって表示期間が変動します。<br/>
						<div class="text-center">
							50 takeno　： １日<br/>
							100 takeno　： ３日<br/>
							200 takeno　： ５日
						</div>
					</v-card-text>

					<v-card-text v-if="itemUseFlg" style="width: 330px;">
						<span v-if="itemList.length == 0">使用できるアイテムがありません。</span>
						<span v-if="itemList.length != 0">どのアイテムを使用しますか？</span>
						<div class="text-center">
							<v-btn class="mt-5" v-for="item in itemList" :key="item.id" @click="popFlg = true; useItem = item">{{ item.item_name }}</p></v-btn>
						</div>
					</v-card-text>
				</v-card>
			</v-menu>

			</span>
		</h2>
		<v-row class="mt-5 mb-10">
			<input v-model="amount" type="number" min="50" max="200" class="text-center amount" step="100" @input="checkAmount()">
			<span class="mt-7" style="font-size: 24px; position: absolute; right: 100px;">takeno</span>
		</v-row>
		<p class="text-center mt-3 ml-6 mb-7">50,100,200の３段階でFIRE DAILYをアップ！</p>
		<v-textarea
			outlined
			name="fire"
			label="FIRE DAILYをアップ"
			v-model="content"
			height="300px"
			style="width:300px; margin: auto;"
			v-on:keydown.enter="enterDown()"
			v-bind:rules="upRules"
        ></v-textarea>
		<v-row>
			<v-btn class="mx-auto gf-btn" style="margin-top: 50px; width: 125px;" dark elevation="5" @click="itemUseFlg = false; popFlg = true;">UPLOAD</v-btn>
			<v-btn class="mx-auto" style="margin-top: 50px; width: 150px;" dark elevation="5" @click="itemUseFlg = true; menu = true">チケットでUPLOAD</v-btn>
		</v-row>

		<v-dialog
			v-model="popFlg"
			persistent
			max-width="90%"
			style="z-index: 999;"
			>
			<v-card class="gradation">
				<v-icon @click="popFlg = false" size="30px" color="black" class="mt-2" style="position: absolute; right: 10px;">mdi-close-circle-outline</v-icon>
				<div class="text-center pt-8 pl-4">
					<div v-if="!confirmFlg">
						<h4>以下の内容でよろしいですか？</h4>
						<p v-if="itemUseFlg" class="text-center mt-4" style="font-size: 20px;"><strong>{{ useItem.item_name }}</strong>で<br/>FIRE DAILYをアップします。</p>
						<p v-if="!itemUseFlg" class="text-center mt-4" style="font-size: 20px;"><strong>{{ amount }} takeno</strong>で<br/>FIRE DAILYをアップします。</p>
					</div>
					<p v-if="confirmFlg" class="text-center mt-4">{{ message }}</p>
				</div>
				<v-card-actions>
					<v-btn block tile color="red" max-width="50" dark depressed small @click="popAct()" class="mb-2">
						確認
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</div>
</template>
<script>
import * as api from '@/api.js'
/* eslint-disable */
export default {
	name: 'Gift',
	data() {
		return{
			amount: 50,
			content: '',

			message: '',
			upRules: [text => text.length <= 595 || '最大文字数は595文字です'],

			popFlg: false,
			confirmFlg: false,

			menu: false,
			itemList: [],
			itemUseFlg: false,
			useItem: '',
		}
	},
	methods: {
		load() {
			api.fire.item().then((res) => {
				switch(res.data.status) {
					case 200:
						this.itemList = res.data.content
						break
					case 500:
						this.pop('エラー', res.data.error[0])
						break
				}
			})
		},
		popAct() {
			if (this.confirmFlg) {
				this.confirmFlg = false
				this.popFlg = false
			}
			else {
				this.confirmFlg = true
				if(this.takeno < this.amount) {
					this.message = '保有takenoが足りません。'
					return
				}
				else if(this.amount == 0) {
					this.message = 'takenoは50,100,200から選択してください。'
					return
				}
				if(this.itemUseFlg) {
					this.use()
				}
				else {
					this.upload()
				}
			}
		},
		upload() {
			api.upload.upload({ amount: this.amount, content: this.content }).then((res) => {
                switch(res.data.status) {
                    case 200:
						this.pop(res.data.content)
                        break
                    case 500:
						this.pop('未入力の必須項目があります。')
                        break
                }
			}).catch()
			this.popFlg = false
		},

		item() {
			this.itemUseFlg = true
			this.menu = true
		},
		use() {
			api.upload.upload({ itemName: this.useItem.item_name, itemNum: this.useItem.item_num, content: this.content }).then((res) => {
                switch(res.data.status) {
                    case 200:
						this.pop(res.data.content)
                        break
                    case 500:
						this.pop('未入力の必須項目があります。')
                        break
                }
			}).catch()
			this.popFlg = false
		},

		checkAmount() {
			if (this.amount > 200) this.amount = 200
			if (this.amount < 50) this.amount = 0
			if(50 < this.amount && this.amount < 100) this.amount = 50
			if(100 < this.amount && this.amount < 200) this.amount = 100
		},

		pop(message) {
			this.popFlg = true
			this.message = message
		},
		
		enterDown() {
			return this.content.replace(/\\n/g,'\n')
		}
	},
	created() {
		this.load()
	},
	computed: {
		takeno() {
			return this.$store.getters.loginInfo.takeno
		}
	}
}
</script>
<style scoped>
.sendTo {
	margin-left: 110px;
	height: 35px;
	min-width: 180px;
	font-size: 18px;
	justify-content: center;
}

input:focus {
	/* border: 2px solid #5EADE4 !important; */
	outline: none;
}

.amount {
	margin: 10px 0 0 100px;
	font-size: 50px;
	height: 55px;
	width: 160px;
}

.gf-btn{
	background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 60%);
}
</style>
